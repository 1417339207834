<template>
  <app-link :to="to">
    <el-menu-item :index="to || index" ref="menuItem">
      <i v-if="iconClass" :class="iconClass"></i>
      <img v-else-if="icon" :src="iconImage" alt="">
      <span>{{title}}</span>
    </el-menu-item>
  </app-link>
</template>

<script>
import AppLink from '@/components/Link'

export default {
  name: 'SidebarMenuItem',
  components: {
    AppLink
  },
  props: {
    to: {
      type: [String, Object]
    },
    index: {
      type: String,
      default: ''
    },
    title: {
      type: String
    },
    iconClass: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    }
  },
  computed: {
    iconImage () {
      if (this.icon) {
        try {
          return require(`@/assets/icons/${this.icon}.svg`)
        } catch (e) {
          return ''
        }
      } else {
        return ''
      }
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      this.$refs.menuItem.handleClick = () => {}
    })
  }
}
</script>
