import { withParams, ref, req } from 'vuelidate/lib/validators/common'
import { get } from 'lodash'

const getIdentificationTokenValidator = (type, value) => {
  switch (type) {
    case 'MOBIB_CARD_NUMBER':
      return luhnAlgorithm(value)
    case 'MOBIB_CHIP_ID':
      return value && (/^[0-9A-Za-z]{16}$/i).test(value)
    default:
      return true
  }
}

export const isExternal = path => {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export const greaterThanZero =
  withParams(
    { type: 'required' },
    value => value
  )

export const greaterThanZeroIf = prop =>
  withParams(
    { type: 'required', prop },
    (value, parentVm) => ref(prop, this, parentVm) ? req(value) && value > 0 : true
  )

export const validateIdentificationToken = prop =>
  withParams(
    { type: 'valid', prop },
    (value, parentVm) => {
      console.log(ref(prop, this, parentVm))
      return getIdentificationTokenValidator(ref(prop, this, parentVm), value)
    }
  )

export const telNumber = (references, target) =>
  withParams(
    { type: 'required' },
    (value) => {
      return !value || get(references, [target, '$refs', 'vueTelInput', 'phoneObject', 'valid'], true)
    }
  )

export const iban =
  withParams(
    { type: 'iban' },
    value => {
      if (!value) {
        return true
      }
      const number = value.trim().slice(4).replace(/\s/g, '')
      if (number.length < 3) {
        return false
      }
      const baseNumber = number.slice(0, 10)
      const checkDigits = Number(number.slice(-2))
      // console.log('baseNumber', baseNumber)
      // console.log('checkDigits', checkDigits)
      // console.log(' Math.floor(baseNumber / 97)',  Math.floor(baseNumber / 97))
      // console.log('baseNumber - Math.floor(baseNumber / 97) * 97',  baseNumber - Math.floor(baseNumber / 97) * 97)
      return baseNumber - Math.floor(baseNumber / 97) * 97 === checkDigits
    }
  )
const regexLatitude = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/
const regexLongitude = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/
const regexBookingId = /[A-Z0-9]{12}/
const regexTime = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/

export const validateTime = value => {
  if (value) {
    return !!(value.toString().match(regexTime))
  }
}

export const validateLatitude = (value) => {
  if (value) {
    return !!(value.toString().match(regexLatitude))
  }
}
export const validateLongitude = (value) => {
  if (value) {
    return !!(value.toString().match(regexLongitude))
  }
}

export const validateBookingId = (value) => {
  if (value) {
    return !!(value.toString().match(regexBookingId))
  }
  return true
}

export const validateMrpOspId = (value) => {
  if (value) {
    return (typeof value === 'string')
  }
  return true
}

export const validateStatus = (value) => {
  if (value || value.length > 0) {
    return Array.isArray(value)
  }
  return true
}

export const luhnAlgorithm = (digits) => {
  if (!digits) {
    return false
  }
  let sum = 0
  for (let i = 0; i < digits.length; i++) {
    let cardNum = parseInt(digits[i])
    if ((digits.length - i) % 2 === 0) {
      cardNum = cardNum * 2
      if (cardNum > 9) {
        cardNum = cardNum - 9
      }
    }
    sum += cardNum
  }
  return sum % 10 === 0
}
