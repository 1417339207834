<template>
  <div class="sidebar" :key="key">
    <div class="logo">
      <base-icon :iconBase64="brandLogo" />
    </div>

    <el-menu
      ref="menu"
      :unique-opened="true"
      active-text-color="#ffffff"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
    >
      <sidebar-menu-item
        to="/dashboard"
        :title="$t('sidebar.menu_item.dashboard')"
        icon-class="ri-line-chart-fill"
      />
      <!--      <div class="divider"/>-->
      <el-menu-item-group :title="companyName">
        <sidebar-menu-item
          to="/company/settings"
          :title="$t('sidebar.menu_item.company_settings')"
          icon-class="ri-profile-line"
        />
        <el-submenu index="manage_users">
          <template slot="title">
            <i class="ri-group-line"></i>
            <span>{{ $t('sidebar.menu_group.users') }}</span>
          </template>
          <sidebar-menu-item
            to="/manage/app-users"
            :title="$t('sidebar.menu_item.manage_app_users')"
          />
          <sidebar-menu-item to="/manage/admins" :title="$t('sidebar.menu_item.manage_admins')" />
          <sidebar-menu-item
            to="/manage/user-groups"
            :title="$t('sidebar.menu_item.manage_user_groups')"
          />
        </el-submenu>
        <sidebar-menu-item
          to="/company/products/list"
          :title="$t('sidebar.menu_item.products')"
          icon-class="ri-ticket-line"
        />
        <sidebar-menu-item
          to="/order-handling"
          :title="$t('sidebar.menu_item.order_handling')"
          icon-class="ri-building-4-line"
        />
      </el-menu-item-group>

      <sidebar-menu-item
        to="/reporting"
        :title="$t('sidebar.menu_item.reporting')"
        icon-class="ri-file-chart-line"
        v-role="['reporting_manager']"
      />
      <el-submenu index="finance" v-if="showCompanyBudgetMenuItem || showInvoicesMenuItem">
        <template slot="title">
          <i class="ri-money-euro-circle-line"></i>
          <span>{{ $t('sidebar.menu_group.finance') }}</span>
        </template>
        <sidebar-menu-item
          v-if="showCompanyBudgetMenuItem"
          to="/finance/company-budget"
          :title="$t('sidebar.menu_item.company_budget')"
        />
        <sidebar-menu-item
          v-if="showInvoicesMenuItem"
          to="/finance/invoices"
          :title="$t('sidebar.menu_item.invoices')"
        />
        <sidebar-menu-item
          to="/finance/all-transactions"
          :title="$t('sidebar.menu_item.all_transactions')"
        />
      </el-submenu>
      <!-- <template v-if="$auth.isOspAdmin()"> -->
      <el-submenu index="communication" v-role="['ospadmin']">
        <template slot="title">
          <i class="ri-mail-send-line"></i>
          <span>{{ $t('sidebar.menu_item.communication') }}</span>
        </template>
        <sidebar-menu-item
          to="/communication/message-channels"
          :title="$t('communication.message_channel_tab.title')"
        />
        <sidebar-menu-item
          to="/communication/message/add"
          :title="$t('communication.send_message.button_step_3')"
        />
      </el-submenu>
      <!-- </template> -->
      <!-- <sidebar-menu-item
        v-role="['ospadmin', 'communication_manager']"
        to="/communication"
        :title="$t('sidebar.menu_item.communication')"
        icon-class="ri-mail-send-line"
      /> -->
      <sidebar-menu-item
        to="/help"
        :title="$t('sidebar.menu_item.help')"
        icon-class="ri-question-line"
      />

      <el-submenu index="user_profile" class="el-menu-footer">
        <template slot="title">
          <i class="ri-account-circle-line"></i>
          <span>{{ fullName }}</span>
        </template>
        <el-menu-item>
          <i class="ri-global-line"></i>
          <el-dropdown @command="changeLanguage" trigger="click">
            <span class="el-dropdown-link">
              {{ $t('sidebar.menu_item.change_language')
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="{ value, label } in languages" :key="value" :command="value"
                >{{ label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <sidebar-menu-item
          to="/change-password"
          :title="$t('sidebar.menu_item.change_password')"
          icon-class="ri-file-user-line"
        />
        <el-menu-item @click="handleSignOut">
          <i class="ri-logout-box-r-line"></i>
          <span>{{ $t('sidebar.menu_item.sign_out') }}</span>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import SidebarMenuItem from './SidebarMenuItem'
import { mapGetters } from 'vuex'
import SidebarHandler from '@/mixins/SidebarHandler'

export default {
  name: 'Sidebar',
  components: {
    SidebarMenuItem
  },
  mixins: [SidebarHandler],
  computed: {
    ...mapGetters({
      companyName: 'corporateAccount/name'
    })
  }
  // mounted () {
  //   console.log(this.$auth.roles())
  // }
}
</script>
