import { get, values, first, keys } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import Auth from '@/app/services/AuthService/Auth'
import { allowViewCompanyBudget } from '@/app/helpers'

export default {
  name: 'SidebarHandler',
  data () {
    return {
      key: null
    }
  },
  computed: {
    ...mapGetters({
      fullName: 'user/fullName',
      language: 'user/language',
      allLanguages: 'i18n/languages',
      brandLogo: 'branding/brandLogo'
    }),
    languages () {
      return this.allLanguages.filter(v => v.value !== this.language)
    },
    showCompanyBudgetMenuItem () {
      return allowViewCompanyBudget()
    },
    showInvoicesMenuItem () {
      return Auth.hasRole('invoice_manager')
    }
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      changeLanguage: 'i18n/changeLang'
    }),
    handleOpen (index) {
      if (index === 'user_profile') {
        return true
      }
      const menu = this.$refs.menu
      const submenu = get(menu.submenus, index, {})
      if (submenu.active) {
        return true
      }
      const item = first(values(submenu.items))
      if (item && item.index) {
        try {
          this.$router.push(item.index)
        } catch (e) {
          console.log(e)
        }
      }
    },
    handleClose () {
      console.log('handleClose')
    },
    checkMenu (value) {
      if (!value) {
        return false
      }
      const menu = this.$refs.menu
      const itemKey = keys(get(menu, 'items', {})).find(v => value.indexOf(v) > -1)
      // console.log(menu.closeMenu())
      // console.log(itemKey)
      if (value && itemKey) {
        menu.updateActiveIndex(itemKey)
        this.closeInactiveMenus()
      } else if (menu) {
        menu.activeIndex = undefined
      }
    },
    closeInactiveMenus () {
      const menu = this.$refs.menu
      const index = menu.activeIndex
      const activeItem = menu.items[index]
      if (!activeItem) return

      const inactiveItems = keys(menu.items).filter(key => key !== index)
      // console.log(inactiveItems)
      inactiveItems.forEach(index => {
        const item = menu.items[index]
        const indexPath = item.indexPath
        // console.log(indexPath)
        indexPath.forEach(index => {
          const submenu = menu.submenus[index]
          // console.log(submenu)
          submenu && !keys(submenu.items).includes(menu.activeIndex) && menu.closeMenu(index, submenu.indexPath)
        })
      })
    },
    handleSignOut () {
      this.signOut()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.checkMenu(this.$route.path)
    })

    // this.$refs.menu.updateActiveIndex(this.$route.path)
  },
  watch: {
    '$route.path' (value) {
      this.checkMenu(value)
    }
  }
}
